import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ConnectButton, useWalletKit } from '@mysten/wallet-kit';
import { TransactionBlock } from '@mysten/sui.js/transactions';
import { SuiClient } from '@mysten/sui.js/client';
import { Wallet, ArrowUpRight, ArrowDownRight, Clock, Shield, TrendingUp, DollarSign, Moon, Sun, Lock, ArrowRight } from 'lucide-react';

const provider = new SuiClient({ url: 'https://fullnode.testnet.sui.io:443' });

export default function Vault() {
  const { currentAccount, signAndExecuteTransactionBlock } = useWalletKit();
  const packageId = '0xc6b2c0eff769fb72eebcb456bf2d8b29fcc6c7d9b5963c9c7ca4b3cbe8fa9ae2';
  const vaultObjectId = '0x297bb4691ebfb31d31f00352fef3fd0ab0ede75dd010c12c1fb35e43f4d18b47';
  
  const [darkMode, setDarkMode] = useState(true);
  const [amount, setAmount] = useState('');
  const [balances, setBalances] = useState({ SUI: '0', PUMPKIN: '0' });
  const [claimableYield, setClaimableYield] = useState({ SUI: '0', PUMPKIN: '0' });
  const [selectedVault, setSelectedVault] = useState('low');
  const [selectedToken, setSelectedToken] = useState('SUI');

  const vaults = {
    low: { 
      name: 'Low Activity Vault', 
      rewardRate: { SUI: '200-250%', PUMPKIN: '450-500%' }, 
      lockTime: 'min 10 mins', 
      leverage: '1x' 
    },
    medium: { 
      name: 'Medium Activity Vault', 
      rewardRate: { SUI: '450-500%', PUMPKIN: '950-1000%' }, 
      lockTime: 'min 10 mins', 
      leverage: '2x' 
    },
    high: { 
      name: 'High Activity Vault', 
      rewardRate: { SUI: '950-1000%', PUMPKIN: '1950-2000%' }, 
      lockTime: 'min 10 mins', 
      leverage: '3x' 
    }
  };
  
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const fetchTokenBalance = useCallback(async (token) => {
    const tx = new TransactionBlock();
    const targetFunction = token === 'SUI'
      ? `${packageId}::Vault::get_sui_balance`
      : `${packageId}::Vault::get_pumpkin_balance`;
  
    tx.moveCall({
      target: targetFunction,
      arguments: [tx.object(vaultObjectId), tx.pure(currentAccount.address)],
    });
  
    try {
      const response = await provider.devInspectTransactionBlock({
        transactionBlock: tx,
        sender: currentAccount.address,
      });
  
      if (response.results && response.results[0].returnValues) {
        const balanceArray = response.results[0].returnValues[0];
        const balance = parseBigIntFromArray(balanceArray);
        return (Number(balance) / 1e9).toFixed(9);
      } else {
        console.error('Balance response does not contain returnValues:', response);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  
    return '0';
  }, [currentAccount, packageId, vaultObjectId]);

  const fetchClaimableYield = useCallback(async () => {
    const tx = new TransactionBlock();
    const targetFunctionSui = `${packageId}::Vault::get_claimable_yield_sui`;
    const targetFunctionPumpkin = `${packageId}::Vault::get_claimable_yield_pumpkin`;

    try {
      tx.moveCall({
        target: targetFunctionSui,
        arguments: [tx.object(vaultObjectId), tx.pure(currentAccount.address)],
      });
      const responseSui = await provider.devInspectTransactionBlock({
        transactionBlock: tx,
        sender: currentAccount.address,
      });

      tx.moveCall({
        target: targetFunctionPumpkin,
        arguments: [tx.object(vaultObjectId), tx.pure(currentAccount.address)],
      });
      const responsePumpkin = await provider.devInspectTransactionBlock({
        transactionBlock: tx,
        sender: currentAccount.address,
      });

      const claimableSUI = responseSui.results[0]?.returnValues?.[0];
      const claimablePUMPKIN = responsePumpkin.results[0]?.returnValues?.[0];

      setClaimableYield({
        SUI: (Number(claimableSUI) / 1e9).toFixed(9),
        PUMPKIN: (Number(claimablePUMPKIN) / 1e9).toFixed(9),
      });
    } catch (error) {
      console.error('Error fetching claimable yield:', error);
    }
  }, [currentAccount, packageId, vaultObjectId]);

  const fetchBalances = useCallback(async () => {
    if (!currentAccount) return;
  
    try {
      const suiBalance = await fetchTokenBalance('SUI');
      const pumpkinBalance = await fetchTokenBalance('PUMPKIN');
  
      setBalances({
        SUI: suiBalance,
        PUMPKIN: pumpkinBalance,
      });
    } catch (error) {
      console.error('Failed to fetch balances:', error);
      setBalances({ SUI: 'Error', PUMPKIN: 'Error' });
    }
  }, [currentAccount, fetchTokenBalance]);

  useEffect(() => {
    if (currentAccount) {
      fetchBalances();
      fetchClaimableYield();
    }
  }, [currentAccount, fetchBalances, fetchClaimableYield]);

  const parseBigIntFromArray = (array) => {
    if (Array.isArray(array) && array.length === 2 && array[1] === 'u64') {
      const numberArray = array[0];
      if (Array.isArray(numberArray)) {
        let value = BigInt(0);
        for (let i = 0; i < numberArray.length; i++) {
          if (typeof numberArray[i] === 'number') {
            value += BigInt(numberArray[i]) * (BigInt(256) ** BigInt(i));
          } else {
            throw new Error(`Unsupported element type: ${typeof numberArray[i]}`);
          }
        }
        return value;
      }
    }
    throw new Error(`Expected an array with format [Array<number>, 'u64'], but got: ${JSON.stringify(array)}`);
  };

  const handleDeposit = async () => {
    if (!currentAccount) {
      alert('Please connect your wallet first');
      return;
    }
  
    try {
      const tx = new TransactionBlock();
      const amountInMist = Math.floor(parseFloat(amount) * 1e9);
  
      const [coin] = tx.splitCoins(tx.gas, [tx.pure(amountInMist)]);
  
      tx.moveCall({
        target: `${packageId}::Vault::deposit_sui`,
        arguments: [
          tx.object(vaultObjectId),
          coin,
        ],
      });
  
      tx.setGasBudget(10000000);
  
      const response = await signAndExecuteTransactionBlock({ 
        transactionBlock: tx,
        options: {
          showEffects: true,
          showEvents: true,
        },
      });
  
      if (response.effects?.status?.status === "success") {
        alert('Deposit successful!');
        setTimeout(() => {
          fetchBalances();
          fetchClaimableYield();
        }, 2000);
      } else {
        throw new Error('Transaction failed: ' + JSON.stringify(response.effects?.status));
      }
    } catch (error) {
      console.error('Deposit failed:', error);
      alert('Deposit failed. See console for details.');
    }
  };
  
  const handleWithdraw = async () => {
    if (!currentAccount) {
      alert('Please connect your wallet first');
      return;
    }
  
    try {
      const tx = new TransactionBlock();
      const amountInMist = Math.floor(parseFloat(amount) * 1e9);
  
      const targetFunction = selectedToken === 'SUI' 
        ? `${packageId}::Vault::withdraw_sui`
        : `${packageId}::Vault::withdraw_pumpkin`;
  
      tx.moveCall({
        target: targetFunction,
        arguments: [
          tx.object(vaultObjectId),
          tx.pure(amountInMist)
        ],
      });
  
      tx.setGasBudget(10000000);
  
      const response = await signAndExecuteTransactionBlock({
        transactionBlock: tx,
        options: {
          showEffects: true,
          showEvents: true,
        },
      });
  
      if (response.effects?.status?.status === "success") {
        alert('Withdrawal successful!');
        setTimeout(() => {
          fetchBalances();
          fetchClaimableYield();
        }, 2000);
      } else {
        throw new Error('Transaction failed: ' + JSON.stringify(response.effects?.status));
      }
    } catch (error) {
      console.error('Withdrawal failed:', error);
      alert('Withdrawal failed. See console for details.');
    }
  };
  
  const handleClaim = async () => {
    if (!currentAccount) {
      alert('Please connect your wallet first');
      return;
    }
  
    try {
      const tx = new TransactionBlock();
  
      tx.moveCall({
        target: `${packageId}::Vault::claim_yield`,
        arguments: [
          tx.object(vaultObjectId),
          tx.object('0x6')
        ],
      });
  
      tx.setGasBudget(10000000);
  
      const response = await signAndExecuteTransactionBlock({
        transactionBlock: tx,
        options: {
          showEffects: true,
          showEvents: true,
        },
      });
  
      if (response.effects?.status?.status === "success") {
        alert('Claim successful!');
        fetchBalances();
        fetchClaimableYield();
      } else {
        throw new Error('Transaction failed: ' + JSON.stringify(response.effects?.status));
      }
    } catch (error) {
      console.error('Claim failed:', error);
      alert('Claim failed. See console for details.');
    }
  };

  const calculateEstimatedEarnings = () => {
    const depositedAmount = parseFloat(balances[selectedToken]);
    const rewardRate = parseFloat(vaults[selectedVault].rewardRate[selectedToken]) / 100;
    const lockTimeInDays = 10 / (24 * 60); // Convert 10 minutes to days
    const estimatedAnnualYield = depositedAmount * rewardRate;
    const estimatedDailyYield = estimatedAnnualYield / 365;
    const estimatedEarnings = estimatedDailyYield * lockTimeInDays;
    return estimatedEarnings.toFixed(9);
  };

  const WelcomeScreen = () => {
    return (
      <div className="relative min-h-screen w-full bg-[#020817] text-white">
        {/* Grid Background */}
        <div 
          className="absolute inset-0"
          style={{
            backgroundImage: `
              repeating-linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px, transparent 50px),
              repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px, transparent 50px)
            `,
            backgroundSize: '50px 50px'
          }}
        />
  
        {/* Content */}
        <div className="relative z-10 flex flex-col items-center justify-center min-h-screen p-8">
          {/* Lock Icon */}
          <div className="mb-8 rounded-full bg-blue-500 p-4">
            <Lock className="h-8 w-8 text-white" />
          </div>
  
          {/* Welcome Text */}
          <h1 className="mb-4 text-4xl font-bold tracking-tight">
            Welcome to AlgoVault
          </h1>
          <p className="mb-12 text-gray-400 text-center max-w-2xl">
            Connect your wallet to access high-yield vaults and start earning rewards
          </p>
  
          {/* Feature Cards */}
          <div className="grid w-full max-w-4xl grid-cols-1 gap-6 mb-12 sm:grid-cols-3">
            <div className="group relative rounded-xl bg-white/5 p-6 backdrop-blur-sm border border-white/10 hover:bg-white/10 transition-all">
              <h3 className="text-xl font-semibold mb-2">High Yields</h3>
              <p className="text-gray-400 mb-8">Earn up to 2000% APY on your deposits</p>
              <div className="absolute bottom-6 right-6">
                <ArrowRight className="h-5 w-5 text-gray-600 transition-transform group-hover:translate-x-1" />
              </div>
            </div>
  
            <div className="group relative rounded-xl bg-white/5 p-6 backdrop-blur-sm border border-white/10 hover:bg-white/10 transition-all">
              <h3 className="text-xl font-semibold mb-2">Secure Storage</h3>
              <p className="text-gray-400 mb-8">Your assets are protected by advanced algorithms</p>
              <div className="absolute bottom-6 right-6">
                <ArrowRight className="h-5 w-5 text-gray-600 transition-transform group-hover:translate-x-1" />
              </div>
            </div>
  
            <div className="group relative rounded-xl bg-white/5 p-6 backdrop-blur-sm border border-white/10 hover:bg-white/10 transition-all">
              <h3 className="text-xl font-semibold mb-2">Instant Access</h3>
              <p className="text-gray-400 mb-8">Withdraw your funds with minimum 10 mins lock</p>
              <div className="absolute bottom-6 right-6">
                <ArrowRight className="h-5 w-5 text-gray-600 transition-transform group-hover:translate-x-1" />
              </div>
            </div>
          </div>
  
          {/* Connect Button */}
          <ConnectButton className="mb-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-lg text-lg transition-colors" />
          
          {/* Documentation Link */}
          <div className="text-sm text-gray-400">
            First time here?{' '}
            <a href="#" className="text-blue-500 hover:text-blue-400">
              Read the documentation
            </a>
          </div>
        </div>
      </div>
    );
  };

  const styles = useMemo(() => ({
    pageWrapper: {
      minHeight: '100vh',
      width: '100%',
      background: `
        linear-gradient(to bottom, rgba(2, 8, 23, 0.95), rgba(2, 8, 23, 0.95)),
        repeating-linear-gradient(to right, transparent 0px, transparent 49px, rgba(255, 255, 255, 0.05) 50px, transparent 51px),
        repeating-linear-gradient(to bottom, transparent 0px, transparent 49px, rgba(255, 255, 255, 0.05) 50px, transparent 51px)
      `,
      backgroundSize: '100% 100%, 50px 50px, 50px 50px',
      padding: '20px',
    },
    dashboard: {
      backgroundColor: 'rgba(3, 7, 17, 0.8)',
      backdropFilter: 'blur(10px)',
      borderRadius: '15px',
      padding: '30px',
      maxWidth: '1200px',
      margin: '40px auto',
      marginTop: '80px',
      boxShadow: '0 4px 32px rgba(0, 0, 0, 0.2)',
      fontFamily: 'Arial, sans-serif',
      color: '#ffffff',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '30px',
    },
    heading: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '700',
      fontSize: '26px',
      letterSpacing: '1.5px',
      color: '#ffffff',
      margin: 0,
    },
    mainnetIndicator: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      color: '#4ade80',
      marginRight: '10px',
    },
    flashingDot: {
      height: '8px',
      width: '8px',
      backgroundColor: '#4ade80',
      borderRadius: '50%',
      marginRight: '5px',
      animation: 'flash 1s infinite alternate',
    },
    accountInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    connectButton: {
      marginRight: '15px',
    },
    balanceDisplay: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      padding: '8px 15px',
      borderRadius: '20px',
      fontWeight: 'bold',
      gap: '10px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
    },
    vaultSelector: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
    },
    vaultOption: {
      flex: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '12px',
      padding: '20px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      color: '#ffffff',
      fontSize: '14px',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        transform: 'translateY(-2px)',
      },
    },
    selectedVault: {
      backgroundColor: 'rgba(59, 130, 246, 0.2)',
      borderColor: '#3b82f6',
    },
    vaultDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginTop: '15px',
    },
    actions: {
      display: 'flex',
      gap: '15px',
      flexWrap: 'wrap',
    },
    select: {
      padding: '12px',
      borderRadius: '5px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      fontSize: '16px',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      color: '#ffffff',
      flex: '1 1 100px',
    },
    input: {
      flex: '1 1 200px',
      padding: '12px',
      borderRadius: '5px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      fontSize: '16px',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      color: '#ffffff',
    },
    depositButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      backgroundColor: '#4ade80',
      color: '#1a2e05',
      padding: '12px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      flex: '1 1 100px',
      transition: 'background-color 0.3s ease',
    },
    withdrawButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      backgroundColor: '#f87171',
      color: '#2a0d0d',
      padding: '12px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      flex: '1 1 100px',
      transition: 'background-color 0.3s ease',
    },
    claimButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      backgroundColor: '#fbbf24',
      color: '#2a2005',
      padding: '12px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      flex: '1 1 100px',
      transition: 'background-color 0.3s ease',
    },
    statsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
      flexWrap: 'wrap',
    },
    statBox: {
      flex: '1 1 200px',
      backgroundColor: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '12px',
      padding: '20px',
      textAlign: 'center',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      color: '#ffffff',
      fontSize: '14px',
    },
  }), []);

  return (
    <div style={styles.pageWrapper}>
      <div className="vault-dashboard" style={styles.dashboard}>
        <header style={styles.header}>
          <h1 style={styles.heading}>AlgoVault by QVL</h1>
          
          {!currentAccount && (
            <div style={{ ...styles.mainnetIndicator, marginTop: '10px' }}>
              <span style={styles.flashingDot}></span>
              <span>Mainnet</span>
            </div>
          )}

          <div style={styles.accountInfo}>
            {currentAccount && (
              <div style={styles.mainnetIndicator}>
                <span style={styles.flashingDot}></span>
                <span>Mainnet</span>
              </div>
            )}
            <button
              onClick={toggleDarkMode}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                marginRight: '15px',
                padding: '8px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ffffff',
              }}
            >
              {darkMode ? <Sun size={20} /> : <Moon size={20} />}
            </button>
            <ConnectButton style={styles.connectButton} />
            {currentAccount && (
              <div style={styles.balanceDisplay}>
                <Wallet size={20} />
                <span>{balances.SUI} SUI</span>
                <span>{balances.PUMPKIN} PUMPKIN</span>
              </div>
            )}
          </div>
        </header>

        {!currentAccount && <WelcomeScreen />}

        {currentAccount && (
          <div style={styles.content}>
            <div style={styles.vaultSelector}>
              {Object.entries(vaults).map(([key, vault]) => (
                <div
                  key={key}
                  style={{
                    ...styles.vaultOption,
                    ...(selectedVault === key ? styles.selectedVault : {}),
                  }}
                  onClick={() => setSelectedVault(key)}
                >
                  <h3>{vault.name}</h3>
                  <div style={styles.vaultDetails}>
                    <span>
                      <TrendingUp size={16} /> SUI Estimated APY (EAPY): {vault.rewardRate.SUI}
                    </span>
                    <span>
                      <TrendingUp size={16} /> PUMPKIN Estimated APY (EAPY): {vault.rewardRate.PUMPKIN}
                    </span>
                    <span>
                      <Clock size={16} /> Lock: {vault.lockTime}
                    </span>
                    <span>
                      <Shield size={16} /> Leverage: {vault.leverage}
                    </span>
                  </div>
                </div>
              ))}
            </div>
    
            <div style={styles.actions}>
              <select
                value={selectedToken}
                onChange={(e) => setSelectedToken(e.target.value)}
                style={styles.select}
              >
                <option value="SUI">SUI</option>
                <option value="PUMPKIN">PUMPKIN</option>
              </select>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder={`Amount in ${selectedToken}`}
                style={styles.input}
              />
              <button onClick={handleDeposit} style={styles.depositButton}>
                <ArrowUpRight size={20} />
                Deposit
              </button>
              <button onClick={handleWithdraw} style={styles.withdrawButton}>
                <ArrowDownRight size={20} />
                Withdraw
              </button>
              <button onClick={handleClaim} style={styles.claimButton}>
                <DollarSign size={20} />
                Claim rewards
              </button>
            </div>
    
            <div style={styles.statsContainer}>
              <div style={styles.statBox}>
                <h4>Total Participated</h4>
                <p>{balances.SUI} SUI</p>
                <p>{balances.PUMPKIN} PUMPKIN</p>
              </div>
              <div style={styles.statBox}>
                <h4>Estimated APY (10 mins)</h4>
                <p>{calculateEstimatedEarnings()} {selectedToken}</p>
              </div>
              <div style={styles.statBox}>
                <h4>Claimable APY Rewards</h4>
                <p>{claimableYield.SUI} SUI</p>
                <p>{claimableYield.PUMPKIN} PUMPKIN</p>
              </div>
              <div style={styles.statBox}>
                <h4>Lock Period</h4>
                <p>{vaults[selectedVault].lockTime}</p>
              </div>
            </div>
    
            <div style={{ textAlign: 'center', marginTop: '20px', color: 'gray', fontSize: '12px' }}>
              <p>* Participation rewards are based on activity and are not guaranteed. Rewards depend on platform engagement and participation.</p>
              <p>
                Please refer to our <a href="/tos" style={{ color: '#3b82f6' }}>Terms of Service</a> for more details.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}