import React from 'react';
import { Link } from 'react-router-dom';
import { useWalletKit, ConnectButton } from '@mysten/wallet-kit'; // Import useWalletKit and ConnectButton
import './NavBar.css';
import logo from '../assets/logo.png';

const NavBar = () => {
  const { currentAccount } = useWalletKit(); // Get wallet connection status

  return (
    <nav className="navbar">
      <div className="navbar-logo-section">
        <img src={logo} alt="QVLSwap Logo" className="navbar-logo" />
        <span className="navbar-brand-text">Quantum Void Labs</span>
      </div>
      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/qvlswap">QVLSwap</Link></li>
        <li><Link to="/vault">Vault</Link></li>
      </ul>
      <div className="navbar-connect">
        {!currentAccount ? (
          <ConnectButton className="connect-wallet-btn" />
        ) : (
          <div className="wallet-info">
            {currentAccount.address.slice(0, 6)}...{currentAccount.address.slice(-4)}
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
