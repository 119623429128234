import React from 'react';
import SwapWidget from '../components/SwapWidget'; 

const Home = () => {
  return (
    <div>
      <h1>Welcome to QVLSwap</h1>
      <SwapWidget /> {/* Include the QVLSwap component here */}
    </div>
  );
};

export default Home;
