import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'; 
import SwapWidget from './components/SwapWidget';
import Vault from './components/Vault';
import NavBar from './components/NavBar';
import TOS from './components/TOS';  // Import the TOS page

const App = () => {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/qvlswap" element={<SwapWidget />} />
          <Route path="/vault" element={<Vault />} />
          <Route path="/tos" element={<TOS />} />  {/* Add TOS Route */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
