import React, { useState } from 'react';
import './SwapWidget.css';
import logo from '../assets/logo.png';

const InHouseAlgo = () => {
  return (
    <div>
      <h2>In-house Algorithm Vault</h2>
      <p>Add $PUMPKIN or $SUI to our algorithm-based strategies and optimize your returns.</p>
      <div className="algo-content">
        <button className="invest-btn">Add liquidity</button>
      </div>
    </div>
  );
};

const SwapWidgetComponent = () => {
  const [activeTab, setActiveTab] = useState('HeroSwap');

  const renderWidget = () => {
    if (activeTab === 'HeroSwap') {
      return (
        <iframe
          src="https://heroswap.com/widget?affiliateName=quantumvoidlabs&destinationAddress=&theme=dark-icy&destinationTicker=SUI"
          title="HeroSwap Widget"
          width="400"
          height="500"
          frameBorder="0"
          style={{ border: '0', borderRadius: '12px' }}
        />
      );
    } else if (activeTab === 'In-house') {
      return <InHouseAlgo />;
    }
  };

  return (
    <div className="App">
      <img src={logo} alt="QVLSwap logo" className="logo" />
      <div className="tab-navigation">
        <button onClick={() => setActiveTab('HeroSwap')} className={activeTab === 'HeroSwap' ? 'active-tab' : ''}>
          HeroSwap
        </button>
        <button onClick={() => setActiveTab('In-house')} className={activeTab === 'In-house' ? 'active-tab' : ''}>
          In-house Algorithms
        </button>
      </div>
      <div className="widget-container">{renderWidget()}</div>
    </div>
  );
};

export default SwapWidgetComponent;
