import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WalletKitProvider } from '@mysten/wallet-kit'; // Import WalletKitProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WalletKitProvider> {/* Wrap App with WalletKitProvider */}
      <App />
    </WalletKitProvider>
  </React.StrictMode>
);

// Measure performance
reportWebVitals();
