import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TOS.css'; // Import the separate CSS file

const TOS = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleBackClick = () => {
    navigate('/vault'); // Navigate to the vault page
  };

  return (
    <div className="tos-container">
      <div className="tos-popup">
        <h1>Terms of Service</h1>
        <p>Welcome to AlgoVault by QVL. By accessing or using our vaults, you agree to the following terms and conditions:</p>
        
        <h2>Participation and Rewards</h2>
        <p>Users participate in the vault system by depositing tokens (SUI or PUMPKIN) into different vaults, each offering potential rewards. These rewards are estimates and not guaranteed. Participation should not be considered an investment.</p>
        
        <h2>No Guarantees</h2>
        <p>There is no expectation of profit. Any potential rewards are based on user participation and can fluctuate.</p>
        
        <h2>User Responsibilities</h2>
        <p>Users are responsible for their participation and outcomes. This system is not an investment vehicle.</p>
        
        <h2>Contact Information</h2>
        <p>If you have any questions about these terms, please contact us at contact@thepumpkintoken.org.</p>

        {/* Back Button */}
        <button className="back-button" onClick={handleBackClick}>
          Back to Vault
        </button>
      </div>
    </div>
  );
};

export default TOS;
